<template>
    <div class="main__block">
        <div class="main__body">
            <h2 class="h2">
                Результаты поиска
            </h2>
            <client-list
                :items="items"
                :controls="controls"
                @control="control"
            />
        </div>

        <div class="main__foot main__bar">
            <button
                class="main__cancel button button--white"
                @click="cancel"
            >
                Искать снова
            </button>
            <button
                class="main__submit button button--green"
                @click="create"
            >
                Новый залогодатель
            </button>
        </div>
    </div>
</template>

<script>
    import clientList from '@/components/client-list.vue';

    export default {
        components: {
            clientList
        },
        props: {
            items: {
                type: Array,
                default() { return []; }
            },
            controls: {
                type: Array,
                default() { return []; }
            }
        },
        methods: {
            control(event, idx) {
                this.$emit('control', event, idx);
                this.$emit('control:' + event, idx);
            },
            create() {
                this.$emit('create');
            },
            cancel() {
                this.$emit('cancel');
            }
        }
    };
</script>
