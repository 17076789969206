<template>
    <div class="main__block">
        <search
            v-if="isStage('search')"
            :mode="mode"
            :query="query"
            @submit="search"
        />
        <result
            v-if="isStage('result')"
            :items="items"
            :controls="controls"
            @control="control"
            @create="create"
            @cancel="showSearch"
        />
    </div>
</template>

<script>
    import { clients } from '@/services';
    import search from './search.vue';
    import result from './result.vue';

    export default {
        components: {
            search,
            result
        },
        props: {
            controls: {
                type: Array,
                default() { return []; }
            }
        },
        data() {
            return {
                stage: 'search',
                query: undefined,
                mode: undefined,
                items: undefined
            };
        },
        methods: {
            isStage(stage) {
                return this.stage === stage;
            },
            showSearch() {
                this.items = undefined;
                this.stage = 'search';
            },
            search(params) {
                this.$store.dispatch('showLoader', { label: 'Поиск клиента' });

                clients.getClients(params.query).then((items) => {
                    this.query = params.query;
                    this.mode = params.mode;

                    this.items = items;
                    this.stage = 'result';
                }).catch((error) => {
                    const err = new Error('Не удалось найти клиентов');
                    err.parent = error;
                    this.$store.dispatch('showError', { err, vm: this });
                }).finally(() => {
                    this.$store.dispatch('hideLoader');
                });
            },
            create() {
                this.$emit('create');
            },
            control(event, idx) {
                this.$emit('control', event, this.items[idx]);
                this.$emit('control:' + event, this.items[idx]);
            }
        }
    };
</script>
