<template>
    <main class="main">
        <div class="main__head">
            <h1 class="h1">
                Поиск залогодателя
            </h1>
        </div>
        <clients
            :controls="controls"
            @prev="prev"
            @create="create"
            @control:update="update"
            @control:pawn="pawn"
        />
    </main>
</template>

<script>
    import clients from '@/views/operations/clients/main.vue';

    export default {
        components: {
            clients
        },
        data() {
            return {
                controls: [
                    { event: 'update', label: 'Редактировать', color: 'yellow' },
                    { event: 'pawn', label: 'Оформить залог', color: 'green' }
                ]
            };
        },
        methods: {
            prev() {
                this.$router.go(-1);
            },
            create() {
                this.$router.push({ name: 'client-create' });
            },
            update(client) {
                this.$store.state.client = client;
                this.$router.push({ name: 'client-update', query: { client_id: client.id } });
            },
            pawn(client) {
                this.$store.state.client = client;
                this.$router.push({ name: 'pawn-create', query: { client_id: client.id } });
            }
        }
    };
</script>
