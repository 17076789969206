<template>
    <form
        action="#"
        class="main__block"
        @submit.prevent="submit"
    >
        <div class="main__body">
            <h2 class="h2">
                Введите критерии поиска
            </h2>

            <div
                class="panel main__section"
                :class="{ 'is-closed': !isType('document'), 'is-opened': isType('document') }"
            >
                <a
                    href="#"
                    class="panel__head"
                    @click.prevent="setType('document')"
                >
                    <span class="h2">Искать по документу</span>
                </a>
                <div class="panel__body form">
                    <field
                        type="document"
                        label="Серия/номер"
                        value=""
                        class="form__field"
                    >
                        <template slot="label">
                            <label for="document_serial">Серия</label> /
                            <label for="document_number">номер</label>
                        </template>
                        <template slot="input">
                            <input
                                id="document_serial"
                                v-model.lazy="val.document_serial"
                                type="text"
                                placeholder="Серия"
                                class="input"
                            >
                            <input
                                id="document_number"
                                v-model.lazy="val.document_number"
                                type="text"
                                placeholder="Номер"
                                class="input"
                            >
                        </template>
                    </field>
                </div>
            </div>

            <div
                class="panel main__section"
                :class="{ 'is-closed': !isType('phone'), 'is-opened': isType('phone') }"
            >
                <a
                    href="#"
                    class="panel__head"
                    @click.prevent="setType('phone')"
                >
                    <span class="h2">Искать по телефону</span>
                </a>
                <div class="panel__body form">
                    <field
                        v-model="val.phone"
                        type="tel"
                        label="Номер телефона"
                        placeholder="+7 000 000 00 00"
                        class="form__field"
                    />
                </div>
            </div>

            <div
                class="panel main__section"
                :class="{ 'is-closed': !isType('person'), 'is-opened': isType('person') }"
            >
                <a
                    href="#"
                    class="panel__head"
                    @click.prevent="setType('person')"
                >
                    <span class="h2">Искать по ФИО</span>
                </a>
                <div class="panel__body form">
                    <field
                        type="person"
                        label="ФИО залогодателя"
                        value=""
                        class="form__field"
                    >
                        <template slot="label">
                            <label for="last-name">ФИО залогодателя</label>
                        </template>
                        <template slot="input">
                            <input
                                id="last-name"
                                v-model.lazy="val.last_name"
                                type="text"
                                placeholder="Фамилия"
                                class="input"
                            >
                            <input
                                id="first-name"
                                v-model.lazy="val.first_name"
                                type="text"
                                placeholder="Имя"
                                class="input"
                            >
                            <input
                                id="patronymic"
                                v-model.lazy="val.patronymic"
                                type="text"
                                placeholder="Отчество"
                                class="input"
                            >
                        </template>
                    </field>
                </div>
            </div>
        </div>

        <div class="main__foot main__bar">
            <button
                type="submit"
                class="main__submit button button--green"
            >
                Найти залогодателя
            </button>
        </div>
    </form>
</template>

<script>
    import field from '@/components/field.vue';

    const fields = {
        document: ['document_serial', 'document_number'],
        phone: ['phone'],
        person: ['last_name', 'first_name', 'patronymic']
    };

    export default {
        components: {
            field
        },
        props: {
            mode: {
                type: String,
                default: 'document'
            },
            query: {
                type: Object,
                default() { return {}; }
            }
        },
        data() {
            let data = {
                type: this.mode,
                fields: fields,
                val: {}
            };
            for (let type in fields) {
                for (let field of fields[type]) {
                    data.val[field] = this.query[field] || '';
                }
            }
            return data;
        },
        methods: {
            setType(type) {
                this.type = type;
            },
            isType(type) {
                return this.type === type;
            },
            serialize() {
                let query = {},
                    fields = this.fields[this.type];

                for (let field of fields) {
                    if (this.val[field] !== undefined) {
                        query[field] = this.val[field];
                    }
                }

                return {
                    query,
                    mode: this.type
                };
            },
            submit() {
                this.$emit('submit', this.serialize());
            }
        }
    };
</script>
